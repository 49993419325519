import type { Optional } from 'utility-types'

import {
  ApolloClient,
  ApolloClientOptions,
  ApolloLink,
  InMemoryCache,
} from '@apollo/client'
import apolloLogger from 'apollo-link-logger'

import notEmpty from 'utils/notEmpty'

import createApolloLink from './middlewares/createApolloLink'
import error from './middlewares/error'
import uri from './middlewares/uri'

const URI_WEB = '/graphql/web'
const URI_GLOBAL = '/graphql/global'

const PublicAppApolloClient = (
  apolloClientOptions?: Optional<ApolloClientOptions<unknown>, 'cache'>
) => {
  const cache = new InMemoryCache()

  return new ApolloClient({
    cache,
    connectToDevTools: import.meta.env.DEV,
    link: ApolloLink.from(
      [
        import.meta.env.DEV ? apolloLogger : null,
        uri(URI_GLOBAL, { conditionKey: 'useWebUri', replaceToUri: URI_WEB }),
        error(),
        createApolloLink(URI_GLOBAL, false, false), // don't use WS, no WS url is exposed in metadata of public pages
      ].filter(notEmpty<ApolloLink>)
    ),
    ...apolloClientOptions,
  })
}

export default PublicAppApolloClient
